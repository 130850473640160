<template>
	<div>
		<!-- fitst -->
		<div
			class="about_bg_first wrapper_container bg_center"
			:style="`background-image: url(${!$store.getters.GetIsH5 ? first_bg : h5_first_bg});`"
		>
			<div class="main_content about_content">
				<h1>About us</h1>
			</div>
		</div>
		<!-- two -->
		<div class="about_bg_two wrapper_container">
			<div class="main_content ke_common_msg">
				TiFi by MIB Group is a digital financial institution that has officially obtained multi financial licenses and
				focuses on the African market.We want to serve the new forces of African society with innovative financial
				models and concepts, and create a digital banking business which is driven by fintech and cloud computing
				technologies.TiFi is devoted to provide safe, convenient and efficient Earned Wage Access (EWA) for employees in
				one intuitive mobile application. It’s a low-cost to the employees,Zero costs to the employer, high-value
				employee benefit for progressive employers that want to attract and retain talent and make a positive impact on
				financial wellbeing
			</div>
		</div>
		<!-- three -->
		<div class="about_bg_three wrapper_container">
			<div class="main_content">
				<h1 class="ke_common_title">Who we are</h1>
				<p class="ke_common_line"></p>
				<div class="content">
					<div class="left_msg">
						<p class="ke_common_msg p_b">Founder and CEO Robin Duan</p>
						<p class="ke_common_msg">
							Robin Duan, founder and CEO of MIB, also Founder/Chairman of Mobvista(1860. HK). Robin worked as a product
							manager for Huawei and UC overseas from 2008. In 2013, he found Mobvista, which now is a leading
							technology platform providing mobile advertising and mobile analytics services to the app developers
							globally, listed on the Main Board of the Stock Exchangeof Hong Kong (01860. HK) since December 2018,
							hitherto has over 700 employeeswith offices in 16 cities across the world. In 2019, Hurun released
							"Post-80s Rich List of Self-made Rich People", Robin ranked 48.
						</p>
					</div>
					<img class="right_img" src="~@/assets/ke-img/AboutUs/three-bg.webp" alt="" />
				</div>
			</div>
		</div>
		<!-- four -->
		<div class="about_bg_four wrapper_container">
			<div class="main_content">
				<h1 class="ke_common_title">Our Team</h1>
				<p class="ke_common_line"></p>
				<div class="msg_container">
					<h2 class="ke_common_msg">Our Culture</h2>
					<p class="ke_common_msg">
						Our fundamental beliefs at TiFi by MIB African Solutions are built on core values of passion, empowerment,
						Professionalism, innovation, and teamwork.
					</p>
					<p class="ke_common_msg">
						We value our employees and strive to create a great workplace where everyone is valued, heard, inspired, and
						encouraged to bring their authentic selves to work.
					</p>
					<p class="ke_common_msg">
						We're committed to providing an excellent employee experience through fulfilling projects, empowerment to
						make a difference, and an environment that inspires innovation. The benefits of working at TiFi by MIB
						African Solutions Ltd include a competitive compensation package, career development opportunities, access
						to wellness resources, and a vibrant global culture.
					</p>
					<h2 class="ke_common_msg">Diverse Culture</h2>
					<p class="ke_common_msg">
						We recognize that a diverse workforce is good for both business growth and individual success and we're
						committed to making sure all employees feel welcome. We do this by celebrating diversity and encouraging
						individuals from different backgrounds and experiences to join us at TiFi by MIB African Solutions Limited.
					</p>
					<p class="ke_common_msg">
						We cultivate a culture where our employees can thrive, feel valued, and achieve great things.
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			first_bg: require('@/assets/ke-img/AboutUs/first-bg.webp'),
			h5_first_bg: require('@/assets/ke-img/AboutUs/h5-first-bg.webp')
		}
	}
}
</script>

<style scoped lang="scss">
.about_bg_first {
	position: relative;
	height: 990px;
	background-image: url('~@/assets/ke-img/AboutUs/first-bg.webp');
	.about_content {
		position: absolute;
		top: 50%;
		left: 15%;
		h1 {
			color: #fff;
			font-size: 100px;
			font-weight: bold;
		}
	}
}
.about_bg_two {
	.main_content {
		padding: 130px 0;
		// font-size: 25px;
		line-height: 38px;
		color: #555770;
	}
}
.about_bg_three {
	background-color: var(--ke-primary-deep-blue);
	padding: 100px 0;
	.main_content {
		h1 {
			color: #fff;
			text-align: center;
		}
		.content {
			display: flex;
			justify-content: space-between;
			margin-top: 100px;
			.left_msg {
				color: #fff;
				width: 50%;
				.p_b {
					margin-bottom: 40px;
				}
				p {
					line-height: 45px;
				}
			}
			.right_img {
				width: 523px;
				height: 540.7px;
			}
		}
	}
}
.about_bg_four {
	background-color: var(--ke-primary-blue);
	padding: 100px 0;
	.main_content {
		h1 {
			text-align: center;
		}
		.msg_container {
			margin-top: 50px;
			h2 {
				font-weight: bold;
				margin: 40px 0;
			}
			p {
				margin-bottom: 40px;
				color: #555770;
			}
		}
	}
}
@media screen and (max-width: 1246px) {
	.about_bg_first {
		height: 790px;
		.main_content {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			h1 {
				text-align: center;
			}
		}
	}
	.about_bg_two {
		.main_content {
			color: #333333;
			line-height: 60px;
		}
	}
	.about_bg_three {
		.main_content {
			.content {
				flex-direction: column-reverse;
				.left_msg {
					width: 100%;
					margin: 50px 0;
					p {
						line-height: 55px;
					}
				}
				.right_img {
					width: 100%;
					height: 750px;
				}
			}
		}
	}
	.about_bg_four {
		.main_content {
			.msg_container {
				h2 {
					margin-top: 80px;
				}
				p {
					color: #333333;
				}
			}
		}
	}
}
</style>
